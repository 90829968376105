
<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h5 class="mb-3">Add New Patient</h5>
        <div class="">
          <patient-create-form />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PatientCreateForm from '@/components/forms/PatientCreateForm.vue'

export default {
  components:{
    PatientCreateForm
  },
  methods: {

  },
}
</script>

<template>
    <div class="patient-create-form">
        <div v-if="!nextActive" class="row">
            <div class="col-lg-8">
                <div class="card">
                    <div class="card-body">
                        <div class="mb-5">
                            <h5 class="">Get Started</h5>
                            <p class="mb-0">Get started by choosing patient category below</p>
                        </div>
                        <div>
                            <label class="d-block mb-3">PATIENT CATEGORY</label>
                            <div>
                                <div @click="patient_category = 'adult'" 
                                    :class="{'active' : patient_category == 'adult'}" class="patient-category mb-3">
                                    <h6 class="mb-2">Adult</h6>
                                    <small class="mb-0">We believe that an adult should have such details like NIN and Phone number.</small>
                                </div>
                                <div @click="patient_category = 'child'"
                                    :class="{'active' : patient_category == 'child'}" class="patient-category">
                                    <h6 class="mb-2">Child</h6>
                                    <small class="mb-0">Choose this when a patient is  below 16 years and does not have a NIN </small>
                                </div>
                            </div>
                            <div class="d-grid mt-5">
                                <button @click="activeNext()" class="btn btn-primary" type="button">CONTINUE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div v-if="patient_category == 'child'">
                <child-patient-form  @addItem="addItem($event)"/>
            </div>
            <div v-if="patient_category == 'adult'">
                <adult-patient-form @addItem="addItem($event)" />
            </div>
        </div>
    </div>
</template>

<script>
import AdultPatientForm from './AdultPatientForm.vue';
import ChildPatientForm from './ChildPatientForm.vue';

export default {
  components: { ChildPatientForm, AdultPatientForm },
    data(){
        return{
            patient_category: "",
            nextActive: false
        }
    },
    methods:{
        activeNext(){
            if(!this.patient_category){
                this.alertError('Please choose patient category'); return;
            }
            this.nextActive = true
        },
        addItem(item){
            this.$router.push({path: '/patients/find', query: { number: item.friendly_id } })
        }
    }
}
</script>

<style scoped>
.patient-create-form .patient-category{
  width: 100%;
  padding: 10px 15px;
  border: 2px solid #eee;
  display: inline-block;
  color: #383838;
  border-radius: 3px;
  cursor: pointer;
}

.patient-create-form .patient-category.active{
  border-color: var(--bs-primary) !important;
  color: var(--bs-primary);
}
</style>
<template>
    <form autocomplete="off" class="">
        <div class="card">
            <div class="card-body">
                <div class="row gy-3 mb-4">
                    <div class="col-12" >
                        <h5 class="mb-2">Child's Information</h5>
                    </div>
                    <div class="col-sm-3 col-lg-2">
                        <label class="form-label d-block" for="ProfileImage">Profile photo</label>
                        <img v-if="data.image" width="100" class="" :src="absoluteUrl(data.image)" alt="profile logo" />
                        <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="100" height="100" viewBox="0 0 256 256" xml:space="preserve">
                            <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                                <path d="M 90 90 H 77.615 c -21.744 -5.296 -43.487 -5.296 -65.231 0 H 0 V 0 h 90 V 90 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(200,209,230); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                                <path d="M 67.319 68.576 l -4.863 -2.716 c -3.576 -1.997 -6.386 -4.994 -8.161 -8.53 c 2.468 -3.578 4.072 -8.116 4.271 -11.885 h 0.022 l 1.346 -6.015 c 0.298 -1.332 -0.306 -2.604 -1.346 -3.293 v -2.179 c 0 -6.227 -4.193 -13.335 -9.906 -15.582 l 0.009 -0.015 c -2.81 -1.268 -6.633 -1.184 -8.423 -0.718 c 1.849 0.664 2.835 2.557 0.425 3.426 l 0.022 0.001 c -5.402 1.796 -9.302 6.882 -9.302 12.887 v 2.179 c -1.041 0.689 -1.644 1.96 -1.346 3.293 l 1.346 6.015 h 0.022 c 0.199 3.769 1.802 8.307 4.271 11.885 c -1.775 3.536 -4.586 6.533 -8.161 8.53 l -4.863 2.716 c -6.357 3.55 -10.296 10.262 -10.296 17.543 V 90 h 65.231 v -3.881 C 77.615 78.838 73.676 72.126 67.319 68.576 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(247,247,247); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                            </g>
                        </svg>
                    </div>
                    <div class="col-md-5 col-lg-3 align-self-end">
                        <profile-image-capture @uploadSuccess="updateImage($event)"/>
                    </div>
                    <div v-for="(item, index) in v$.data.image.$errors"
                        :key="index" class="col-12">
                        <span v-if="item.$message" class="small text-danger">{{ item.$message }}</span>
                    </div>
                </div>
                <div class="row gy-3">
                    <div class="col-md-6 col-lg-4">
                        <label class="form-label" for="firstName">First Name <span class="text-danger">*</span></label>
                        <input id="firstName" type="text" class="form-control" v-model="data.first_name"
                        :class="{ 'is-invalid': v$.data.first_name.$error }" placeholder="First name" name="first_name" />
                        <div v-for="(item, index) in v$.data.first_name.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <label class="form-label" for="middleName">Middle Name</label>
                        <input id="middleName" type="text" class="form-control" v-model="data.middle_name"
                        placeholder="Middle name" name="middle_name" />
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <label class="form-label" for="LastName">Last Name <span class="text-danger">*</span></label>
                        <input id="LastName" type="text" class="form-control" v-model="data.last_name"
                        :class="{ 'is-invalid': v$.data.last_name.$error }"  placeholder="Last Name" name="last_name" />
                        <div v-for="(item, index) in v$.data.last_name.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                    </div>               
                    <div class="col-12">
                        <div class="row gy-3">
                            <div class="col-lg-5">
                                <label class="form-label">
                                    Date of birth <span class="text-danger">*</span>
                                </label>
                                <div class="input-group">
                                    <select @change="createDOB()" v-model="manualDate.day" name="day"
                                        :class="{ 'is-invalid': v$.data.dob.$error  }" class="form-select">
                                        <option disabled value="">Day </option>
                                        <option v-for="(day, dIndex) in days" :key="dIndex"
                                            :value="day.value">{{day.label}}</option>
                                    </select>
                                    <select @change="createDOB()" v-model="manualDate.month" name="month"
                                    :class="{ 'is-invalid': v$.data.dob.$error  }" class="form-select">
                                        <option disabled value="">Month</option>
                                        <option v-for="(month, mIndex) in months" :key="mIndex"
                                        :value="month.value">{{month.label}}</option>
                                    </select>
                                    <select @change="createDOB()" v-model="manualDate.year" name="year"
                                        :class="{ 'is-invalid': v$.data.dob.$error  }" class="form-select">
                                        <option disabled value=""> Year </option>
                                        <option v-for="(year, yIndex) in years" :key="yIndex"
                                        :value="year.value">{{year.label}}</option>
                                    </select>
                                </div>
                                <div v-for="(item, index) in v$.data.dob.$errors"
                                    :key="index" class="text-danger">
                                    <span v-if="item.$message">{{ item.$message }}</span>
                                </div> 
                            </div>
                            <div class="col-md-6 col-lg-3">
                                <label for="inputAge" class="form-label"> Age (yrs) <span class="text-danger">*</span></label>
                                <input id="inputAge" disabled label="Age" v-model="data.age" type="number"
                                    placeholder="Enter age" name="age" class="form-control"/>
                                <div v-for="(item, index) in v$.data.age.$errors"
                                    :key="index" class="invalid-feedback">
                                    <span v-if="item.$message">{{ item.$message }}</span>
                                </div> 
                            </div>
                            <div class="col-md-6 col-lg-4">
                                <label for="gender" class="form-label">Gender <span class="text-danger">*</span></label>
                                <select v-model="data.gender" name="gender" id="gender" class="form-select"
                                :class="{ 'is-invalid': v$.data.gender.$error }" >
                                <option disabled :value="null" >--select gender--</option>
                                <option v-for="(gender, gdIndex) in genderOptions" :key="gdIndex" 
                                    :value="gender.value">{{gender.label}}</option>
                                </select>
                                <div v-for="(item, index) in v$.data.gender.$errors"
                                    :key="index" class="invalid-feedback">
                                    <span v-if="item.$message">{{ item.$message }}</span>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <div class="card-body">
                <div class="row gy-3">
                    <div class="col-12" >
                        <h5 class="mb-2">Child's Address</h5>
                    </div>
                    <div class="col-sm-12">
                        <label for="address" class="form-label"> Address </label>
                        <input v-model="data.address" id="address" name="address"
                        class="form-control" type="text" placeholder="Address">
                    </div>
                    <div class="col-md-4">
                        <label for="country_id" class="form-label">Country</label>
                        <select @change="countryChange()" v-model="data.country_id" 
                            name="country_id" id="country_id" class="form-select">
                        <option disabled value="" >--choose country--</option>
                        <option v-for="(country, coIndex) in countryList" :key="coIndex" 
                        :value="country.id">{{country.name}}</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label for="state_id" class="form-label">State</label>
                        <select @change="stateChange()" v-model="data.state_id" 
                        name="state_id" id="state_id" class="form-select">
                        <option disabled value="" >--choose state--</option>
                        <option v-for="(state, sIndex) in stateOptions" :key="sIndex" 
                        :value="state.id">{{state.name}}</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label for="local_government_id" class="form-label">L.G.A.</label>
                        <select v-model="data.local_government_id" name="local_government_id"
                        id="local_government_id" class="form-select">
                        <option disabled value="" >--choose LGA--</option>
                        <option v-for="(city, ctIndex) in lgaOptions" :key="ctIndex" 
                        :value="city.id">{{city.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row gy-3">
                    <div class="col-12" >
                        <h5 class="mb-2">Parent or Guardian's Information</h5>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <label class="form-label" for="ParentFirstName">Parent First Name <span class="text-danger">*</span></label>
                        <input id="ParentFirstName" type="text" class="form-control" v-model="data.parent_first_name"
                        :class="{ 'is-invalid': v$.data.parent_first_name.$error }" placeholder="Parent First name" name="parent_first_name" />
                        <div v-for="(item, index) in v$.data.parent_first_name.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <label class="form-label" for="ParentMiddleName">Parent Middle Name <span class="text-danger">*</span></label>
                        <input id="ParentMiddleName" type="text" class="form-control" v-model="data.parent_middle_name"
                        placeholder="Parent Middle name" name="parent_middle_name" />
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <label class="form-label" for="ParentLastName">Parent Last Name <span class="text-danger">*</span></label>
                        <input id="ParentLastName" type="text" class="form-control" v-model="data.parent_last_name"
                        :class="{ 'is-invalid': v$.data.parent_last_name.$error }" placeholder="Parent Last name" name="parent_last_name" />
                        <div v-for="(item, index) in v$.data.parent_last_name.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label" for="inputParentMobile">Parent Phone Number <span class="text-danger">*</span></label>
                        <input id="inputParentMobile" autocomplete="false" type="text" class="form-control" v-model="data.parent_mobile"
                            :class="{ 'is-invalid': v$.data.parent_mobile.$error  }" placeholder="Parent mobile" name="parent_mobile" />
                        <div v-for="(item, index) in v$.data.parent_mobile.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>  
                    </div>
                    <div class="col-md-6">
                        <label for="parent_gender" class="form-label">Parent Gender <span class="text-danger">*</span></label>
                        <select v-model="data.parent_gender" name="parent_gender" id="parent_gender" class="form-select"
                        :class="{ 'is-invalid': v$.data.parent_gender.$error }" >
                        <option disabled :value="null" >--select parent gender--</option>
                        <option v-for="(parentGender, gdIndex) in genderOptions" :key="gdIndex" 
                            :value="parentGender.value">{{parentGender.label}}</option>
                        </select>
                        <div v-for="(item, index) in v$.data.parent_gender.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div> 
                    </div>
                    <div class="col-md-6">
                        <label class="form-label" for="inputParentOccupation">Parent Occupation <span class="text-danger">*</span></label>
                        <input id="inputParentOccupation" autocomplete="false" type="text" class="form-control" v-model="data.parent_occupation"
                            :class="{ 'is-invalid': v$.data.parent_occupation.$error  }" placeholder="Parent occupation" name="parent_occupation" />
                        <div v-for="(item, index) in v$.data.parent_occupation.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>  
                    </div>
                    <div class="col-md-6">
                        <label class="form-label" for="inputParentReligion">Parent Religion <span class="text-danger">*</span></label>
                        <input id="inputParentReligion" autocomplete="false" type="text" class="form-control" v-model="data.parent_religion"
                            :class="{ 'is-invalid': v$.data.parent_religion.$error  }" placeholder="Parent Religion" name="parent_religion" />
                        <div v-for="(item, index) in v$.data.parent_religion.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row gy-3 mb-4">
                    <div class="col-12" >
                        <h5 class="mb-3">National ID Number of Parent or Guardian</h5>
                        <div class="alert alert-warning" role="alert">
                            <strong>NOTE!</strong>
                            Inform Patient that NIN will be shared with an external service provider for verification purposes.
                        </div>
                    </div>
                    <div class="col-12">
                        <label class="form-label" for="inputNIN"> Parent/Guardian's National Identification Number (NIN) <span class="text-danger">*</span></label>
                        <input id="inputNIN" autocomplete="false" type="text" class="form-control" v-model="data.parent_nin"
                            :class="{ 'is-invalid': v$.data.parent_nin.$error  }" placeholder="Parent's NIN" name="parent_nin" />
                        <div v-for="(item, index) in v$.data.parent_nin.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3 justify-content-end">
            <div class="col-12">
                <div class="mt-3 d-flex justify-content-between">
                    <button class="btn btn-primary px-lg-5 btn-lg me-2" @click.prevent="confirm()"
                    type="button"> Submit </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, email, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import IsLoading from '@/components/IsLoading.vue'
import ProfileImageCapture from '@/components/forms/ProfileImageCapture.vue';

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
       IsLoading,
       ProfileImageCapture
    },
    data(){
        return{
            isLoading: false,
            data: {
                first_name: "",
                middle_name: "",
                last_name: "",
                gender: null,
                dob: null,
                age: null,
                image: "",
                address: "",
                country_id: "",
                state_id: "",
                local_government_id: "",
                patient_category: "child",
                has_nin: false,

                parent_first_name: "",
                parent_middle_name: "",
                parent_last_name: "",
                parent_mobile: "",
                parent_gender: null,
                parent_occupation: "",
                parent_religion: "",
                parent_nin: "",
            },
            genderOptions: [
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
                { label: 'Others', value: 'others' }
            ],
            manualDate:{
                day: "",
                month: "",
                year: ""
            },
            mirrorData: null,
            stateOptions:[],
            lgaOptions:[],

        }
    },
    validations() {
        return {        
            data:{
                first_name: {
                    required: helpers.withMessage("First name is required", required),
                },
                last_name: {
                    required: helpers.withMessage("Last name is required", required),
                },
                gender: {
                    required: helpers.withMessage("Select gender", required),
                },  
                dob: {
                    required: helpers.withMessage("Date of birth is required", required),
                }, 
                age: {
                    required: helpers.withMessage("Age is required", required),
                },
                image: {
                    required: helpers.withMessage("Profile photo is required", required),
                },
                parent_first_name: {
                    required: helpers.withMessage("Parent First Name is required", required),
                }, 
                parent_last_name: {
                    required: helpers.withMessage("Parent Last Name is required", required),
                }, 
                parent_occupation: {
                    required: helpers.withMessage("Parent Occupation is required", required),
                }, 
                parent_religion: {
                    required: helpers.withMessage("Parent Religion is required", required),
                },   
                parent_mobile: {
                    required: helpers.withMessage("Parent Phone Number is required", required),
                },   
                parent_gender: {
                    required: helpers.withMessage("Parent Phone Number is required", required),
                },  
                parent_nin: {
                    required: helpers.withMessage("Parent NIN number is required", required),
                }   
            }
        }
    },
    computed:{
        countryList(){
            return this.$store.state.countries
        },
        days() {
            return Array.from({ length: 31 }, (_, index) => {
                const dayValue = index + 1;
                return {
                    value: dayValue,
                    label: dayValue.toString().padStart(2, '0')
                };
            });
        },
        months() {
            const monthNames = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
            return Array.from({ length: 12 }, (_, index) => {
                const monthValue = index + 1;
                return {
                    value: monthValue,
                    label: monthNames[index]
                };
            });
        },
        years() {
            let factor = 0
            let startYear = new Date().getFullYear() - factor;
            return Array.from({ length: 16 }, (_, index) => {
                const yearValue = startYear - index;
                return {
                    value: yearValue,
                    label: yearValue.toString()
                };
            });
        },
    },
    methods:{
        confirm(){
            this.v$.$validate().then(result =>{
                if (!result){ 
                    this.alertError('Form is not valid'); return;
                }
                this.confirmAction({
                    text: "<h6>Acknowledge that all entered details are accurate. <h6> Also inform the patient that the provided NIN will be shared with an external service provider for verification purposes.",
                    }).then((result) => {
                    if (result.value) { this.create(); }
                });
            })
        },
        create() {
            let formData = new FormData();
            formData.append('data', JSON.stringify(this.data))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post('/patients/create', formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                    this.$emit('addItem', response.data.data)
                    this.resetForm();
                }
            })
        },
        createDOB(){
            var inputDate = this.manualDate;
            if(!inputDate.day || !inputDate.month || !inputDate.year){
                this.data.dob = ""; return;
            }
            var date = new Date(inputDate.year, inputDate.month - 1, inputDate.day);
            this.data.dob =  date;
            var age = this.$filters.calculateAge(date);
            this.data.age = age > 1 ? age : 1;
        },
        updateImage(image){
            this.data.image = image
        }, 
        countryChange(initialize = true){
            if(initialize){
                this.data.state_id = this.data.local_government_id = "";
                this.stateOptions = this.lgaOptions = [];
            }
            if(!this.data.country_id){
                return
            }
            this.isLoading = true
            this.$http.get(`${this.absoluteUrl()}/api/states?country_id=${this.data.country_id}`)
                .then((response) => {
                    this.isLoading = false
                    if(response.data.success){
                        this.stateOptions = response.data.data
                    }
                })
        },
        stateChange(initialize = true){
            if(initialize){
                this.data.local_government_id = "";
                this.lgaOptions = [];
            }
            if(!this.data.country_id || !this.data.state_id){
                return
            }
            this.isLoading = true
            this.$http.get(`${this.absoluteUrl()}/api/local-governments?country_id=${this.data.country_id}&state_id=${this.data.state_id}`)
                .then((response) => {
                    this.isLoading = false
                    if(response.data.success){
                        this.lgaOptions = response.data.data
                    }
                })
        },
        resetForm(){
            this.data = {...this.mirrorData}
            this.v$.$reset()
            this.$emit("closeMe")
        },
        getOptionData(){
            this.$store.dispatch('fetchCountries')
        },
    },
    created(){
        this.getOptionData()
    },
    mounted(){
        this.mirrorData = JSON.parse(JSON.stringify(this.data))
    }
}
</script>

<style scoped>
</style>